<div *ngIf="!isDesktopMode() && openFullMenu && myProfile" class="full-nav-menu-mobile ">



  <div class="icon-container">

    <img *ngIf="myProfile?.corporate?.backofficeLogoUrl" alt="logo" class="nav-logo-corporate clickable" (click)="goToMyProperties()" [src]="myProfile.corporate.backofficeLogoUrl">

    <div (click)="openFullMenu = false; openProfile()" class=" clickable profile-class" [routerLink]="['setup/profile']">
      <img *ngIf="myProfile?.pictureUrl" [src]="myProfile.pictureUrl" alt="avatar" class="nav-avatar">
      <span *ngIf="!myProfile?.pictureUrl" class="roboto Large nav-avatar-placeholder">
                {{myProfile?.firstName?.substring(0, 1).toUpperCase()}}
      </span>
    </div>

    <div class="margin-left-16 roboto Large " *ngIf="myProfile.influencerDetails != null">
      <img alt="avatar"
           style="height: 45px;" src="../../../../../assets/images/insta.png" >
    </div>

    <!--<div class="margin-left-16 roboto Large nav-alert-placeholder" *ngIf="myProfile.influencerDetails == null">
      <img alt="avatar"
           src="../../../../../assets/icons/guest-card/ring.svg" style="width: 25px; margin-left: 4px; margin-bottom: 3px;">
      <div class="badge">12</div>
    </div>-->

    <!--<div class="margin-left-16 roboto Large nav-alert-placeholder" *ngIf="myProfile.influencerDetails == null">
      <img alt="avatar" src="../../../../../assets/icons/shopping-basket.svg"
           style="width: 25px;  margin-bottom: 3px;">
      <div class="badge">41</div>
    </div>-->

    <div class="margin-left-16 roboto Large nav-alert-placeholder" appPermissionClaim [requiredPermission]="permissions.BOOKING_COMMUNICATION"
         *ngIf="myProfile.influencerDetails == null" (click)="openFullMenu = false" [routerLink]="['dashboard/thread']">
      <img alt="avatar" src="../../../../../assets/icons/guest-card/mail-inbox.svg"
           style="width: 25px;  margin-bottom: 3px;">
      <div *ngIf="currentThreadCount>0" class="badge">{{currentThreadCount}}</div>
    </div>


    <!--<div class="margin-left-16 roboto Large nav-alert-placeholder" appPermissionClaim [requiredPermission]="permissions.BOOKING_INTERNALCHAT"
    *ngIf="myProfile.influencerDetails == null" (click)="openFullMenu = false" [routerLink]="['dashboard/internal-chat']">
 <img alt="avatar" src="../../../../../assets/icons/people.png"
      style="width: 25px;  margin-bottom: 3px;">
 <div *ngIf="currentThreadCount>0" class="badge">{{currentThreadCount}}</div>
</div>-->


  </div>

  <div class="nav-profile-name">{{myProfile.firstName}}</div>

  <div *ngIf="myProfile.influencerDetails == null">
    <div class="menu-item-title">{{'cc3-nav-bar.menu' | translate}}</div>

    <div id="dropDownMenu_mobile">
      <div (click)="openMenu(i, tab)" *ngFor="let tab of subItemsTabs; let i = index" class="menu-item"
           id="{{'subtab-mobile' + i}}" >
        <div *ngIf="tab.title !== 'sep' && !tab.onlyDesktop && tab.title != 'ver'" class="menu-container-flex" >
          <div [ngClass]="{'tab-disabled': tab.disabled , 'tab-bold': tab.bold}">{{tab.title}}</div>
          <img style=" transform: rotate(180deg);" src="assets/icons/arrow-down.svg">
        </div>
      </div>
    </div>
  </div>

  <div class="menu-item-title">{{'cc3-nav-bar.bookings' | translate}}</div>
    <div id="dropDownMenu_mobile3" >
      <div class="menu-item" >
        <div  class="menu-container-flex" (click)="goToGenericLinks()">
          <div >{{'cc3-nav-bar.generic-bookings-list' | translate}}</div>
          <img style=" transform: rotate(180deg);" src="assets/icons/arrow-down.svg">
        </div>
      </div>
    </div>

    <div class="menu-item-title">{{'cc3-nav-bar.account' | translate}}</div>
    <div id="dropDownMenu_mobile2">
      <div (click)="openMenu(i, tab)" *ngFor="let tab of subItemsProfileTabs; let i = index" class="menu-item"
           id="{{'subtab2-mobile' + i}}">
        <div *ngIf="tab.title !== 'sep' && tab.title != 'ver'" class="menu-container-flex">
          <div [ngClass]="{'tab-disabled': tab.disabled, 'tab-bold': tab.bold}">{{tab.title}}</div>
          <img style=" transform: rotate(180deg);" src="assets/icons/arrow-down.svg">
        </div>
        <div *ngIf="tab.title == 'ver'" class="version">Yaago {{utilsService.getVesion()}} - Copyright 2023</div>
      </div>
    </div>

  <div class="empty-space"></div>

</div>

<div *ngIf="isDesktopMode()" id="NavBarForDeskTop" class="NavBarForDeskTopClass">
  <div style="position: relative">
    <div class="full-top-bar">
      <div class="nav-bar-top">
        <img  *ngIf="!myProfile?.corporate?.backofficeLogoUrl" alt="logo" class="nav-logo clickable" (click)="goToMyProperties()" src="../../../../../assets/images/logo/favicon.svg">

        <img *ngIf="myProfile?.corporate?.backofficeLogoUrl" alt="logo" class="padding-left-8 nav-logo-corporate clickable" (click)="goToMyProperties()" [src]="myProfile.corporate.backofficeLogoUrl">

        <div class="nav-item-container">

          <div (click)="openMenu(i, tab)" *ngFor="let tab of tabs; let i = index" class="nav-item" id="{{'tab' + i}}">
            <div>
              {{tab.title}}
              <img *ngIf="tab.subItems != null" alt="arrow-down"
                   class="margin-left-8" src="../../../../../assets/icons/arrows/arrow-down.svg">

            </div>
            <div *ngIf="currentNavMenuItem == tab" class="nav-underline">&nbsp;</div>
          </div>

        </div>

        <div class="icon-container">


        <app-button [type]="'ternary'" [text]="'cc3-nav-bar.faq' | translate" (debounceClick)="openFaq()"></app-button>
          <div class="y3-margin-left-16" style="line-height: 12px">
            <mat-icon class="material-symbols-outlined y3-clickable  color-dunnessant" style=" font-size: 35px; " [routerLink]="'setup/release-note'">
              new_releases
            </mat-icon>
          </div>

        <!--<div class="margin-left-16 roboto Large nav-alert-placeholder" >
          <a target="_blank" href="https://help.yaago.com/fr/">
            <img alt="avatar" src="assets/icons/question.png"
                 style="width: 25px;  margin-bottom: 3px;">
          </a>
        </div>-->

        <!--<div class="margin-left-16 roboto Large nav-alert-placeholder"  [routerLink]="['dashboard/internal-chat']">
        <img alt="avatar" src="../../../../../assets/icons/people.png"
        style="width: 40px;
        padding: 3px; margin-bottom: 3px;"  >
        </div>-->


          <div class="margin-left-16 nav-alert-placeholder">
            <button mat-icon-button [matMenuTriggerFor]="menuof" >
              <img class="m0-flag " src="{{utilsService.getLanguageFlag(translateService.currentLang)}}" alt="Flag">
            </button>
            <mat-menu #menuof="matMenu" style="z-index: 100">
              <button *ngFor="let l of getLanguages()"  mat-menu-item (click)="manageChangeLanguage(l)" style="line-height: 25px; height: 25px;">
                <div class="y3-flex-row y3-flex-align-center" >
                  <img class="m0-flag " src="{{utilsService.getLanguageFlag(l)}}" alt="Flag">
                  <div class="padding-left-8 y3-Small">{{getNonTranslatedLanguageName(l)}}</div>
                </div>
              </button>
            </mat-menu>
          </div>


          <div class="margin-left-8 roboto Large nav-alert-placeholder" appPermissionClaim [routerLink]="['dashboard/generic-link']">
            <img alt="avatar" src="../../../../../assets/icons/guest-card/generic-link.png" style="width: 25px;  margin-bottom: 3px;">
            <div *ngIf="currentPendingGenericLink > 0" class="badge">{{currentPendingGenericLink}}</div>
          </div>


        <div class="margin-left-8 roboto Large nav-alert-placeholder" appPermissionClaim [requiredPermission]="permissions.BOOKING_COMMUNICATION" *ngIf="myProfile.influencerDetails == null" [routerLink]="['dashboard/thread']">
          <img alt="avatar" src="../../../../../assets/icons/guest-card/mail-inbox.svg" style="width: 25px;  margin-bottom: 3px;">
          <div *ngIf="currentThreadCount>0" class="badge">{{currentThreadCount}}</div>
        </div>


          <div class="margin-left-8 clickable" appPermissionClaim [routerLink]="['setup/academy']">
            <img alt="avatar" src="../../../../../assets/icons/Logo-Yaago-Academy.png" class="nav-avatar2" style="object-fit: contain">
          </div>

        <div (click)="openProfile()" class="margin-left-8 clickable profile-class">
          <img *ngIf="myProfile?.pictureUrl" [src]="myProfile.pictureUrl" alt="avatar" class="nav-avatar">
          <span *ngIf="!myProfile?.pictureUrl" class="roboto Large nav-avatar-placeholder">
                {{myProfile?.firstName?.substring(0, 1).toUpperCase()}}
          </span>

        </div>
      </div>
      </div>

      <div class="local-incident" *ngIf="this.incidentNotes?.length > 0 && this.incidentNotes[0].open">
          <mat-icon>warning</mat-icon>
          <div class="y3-margin-left-8 y3-margin-right-8">{{this.incidentNotes[0].description}}</div>
          <mat-icon>warning</mat-icon>
      </div>

      <div class="local-end-incident" *ngIf="this.incidentNotes?.length > 0 && !this.incidentNotes[0].open">
        <mat-icon>check_circle</mat-icon>
        <div class="y3-margin-left-8 y3-margin-right-8">{{this.incidentNotes[0].description}}
        <span *ngIf="this.incidentNotes[0].link"><a [href]="incidentNotes[0].link">{{incidentNotes[0].linkText}}</a> </span></div>
      </div>


      <div class="core1-postmark-div" *ngIf="utilsService.hasPendingEmailValidation(myCorporate)">
        <div>{{'cc3-nav-bar.postmark-text' | translate}}</div>
        <div style="display: flex; flex-flow: row; align-items: center">
          <app-button [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.postmark-resend' | translate" (debounceClick)="resend_postmark()"></app-button>
        </div>
      </div>


      <div class="" *ngIf="bookingDotComChallenge.length>0">
         <div class="core1-booking-div" *ngFor="let bookingDotComAccount of bookingDotComChallenge">
            <div>{{'cc3-nav-bar.booking-dot-com-text' | translate}} : {{bookingDotComAccount.bookingDotComEmail}}</div>
             <div class="y3-flex-row">
              <div style="display: flex; flex-flow: row; align-items: center" class="y3-margin-right-8">
                <app-button [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.booking-dot-com-ask-for-code' | translate" (debounceClick)="bookingRequestCode(bookingDotComAccount)"></app-button>
              </div>
               <div *ngIf="demandalreadyasked" class="y3-flex-row y3-flex-align-center">
                 <input class="y3-input-text-small y3-margin-right-8" [(ngModel)]="challengeCode" >
                 <div style="display: flex; flex-flow: row; align-items: center">
                   <app-button [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.booking-dot-com-send-code' | translate" (debounceClick)="bookingSendCode(bookingDotComAccount, challengeCode)"></app-button>
                 </div>
               </div>
           </div>
         </div>
      </div>





      <div class="local-trial-div" *ngIf="(myProfile?.hasPaymentMethod && utilsService.diffDays(myProfile.trialPeriodOverDate) >= 0) || (!myProfile.hasPaymentMethod && !myProfile.trialPeriodOver) && myProfile.corporateOwner && !myProfile.beta">
        <div>{{'cc3-nav-bar.trial-period' | translate}} <strong>{{utilsService.diffDays(myProfile.trialPeriodOverDate)}} {{'y0-general.days' | translate}}</strong> </div>
        <div>{{'cc3-nav-bar.subscription' | translate}} <strong>{{getSubscriptionName()}}</strong></div>
        <div style="display: flex; flex-flow: row; align-items: center">
          <!--<app-button [type]="'ternary-light'" [text]="'cc3-nav-bar.schedule-meeting' | translate" (debounceClick)="yaago_onboarding()"></app-button>-->
          <div *ngIf="!this.myProfile.hasPaymentMethod" style="width: 16px"></div>
          <app-button *ngIf="!this.myProfile.hasPaymentMethod" [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.payment-method' | translate" (debounceClick)="yaago_subscribe()"></app-button>
        </div>
      </div>


      <div class="core1-postmark-div" *ngIf="isVatConcern()">
        <div>{{'cc3-nav-bar.vat-missing-text' | translate}}</div>
        <div style="display: flex; flex-flow: row; align-items: center">
          <app-button [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.goto-profile' | translate"  [routerLink]="['setup/profile']"></app-button>
        </div>
      </div>

    </div>


  </div>

  <div class="dropDownMenu" id="dropDownMenu">
    <div (click)="openMenu(i, tab)" *ngFor="let tab of subItemTabSelected; let i = index" class="dropDownMenu-item"
         id="{{'subtab' + i}}" >
      <div *ngIf="tab.title !== 'sep' && tab.title != 'ver' && tab.title != 'Aide'" class="dropDownMenu-item-title" [ngClass]="{'tab-disabled': tab.disabled,'tab-bold': tab.bold}" (click)="clickNavItem(tab)">{{tab.title}}
        <div *ngIf="tab.value =='platforms' && myProfile?.personAlerts?.length > 0 " class="badge">{{ myProfile?.personAlerts?.length}}</div>
      </div>

      <hr *ngIf="tab.title == 'sep'" class="dropDownMenu-sep">
      <div *ngIf="tab.title == 'ver'" class="version">Yaago {{utilsService.getVesion()}} - Copyright 2024</div>

    </div>

  </div>
</div>

<div id="NavBarForMobile" class="nav-bar-container-mobile" *ngIf="!isDesktopMode() && myProfile?.influencerDetails == null">



  <div  class="nav-bar-container-mobile" >
    <div class="nav-bar-bottom">
      <div class="y3-flex-column y3-full-width">

      <div class="y3-flex-row ">
        <div appPermissionClaim [requiredPermission]="permissions.TASKS_LISTING" [corporateId]="myProfile.corporateId" class="nav-bar-bottom-item" (click)="goToDashboard(); currentMobileTab='dashboard'">
          <img *ngIf="currentMobileTab==='dashboard'" class="icon-menu-svg" src="assets/icons/bottom-menu/task-list-active.png">
          <img *ngIf="currentMobileTab!=='dashboard'" class="icon-menu-svg" src="assets/icons/bottom-menu/task-list.png">
          <div class="text-container">
            <p class="icon-descr-text " [ngClass]="{'icon-descr-text-bold': currentMobileTab=='dashboard'}">{{'cc3-nav-bar.tasks-list' | translate}}</p>
            <!--<div *ngIf="currentMobileTab == 'dashboard'" class="nav-underline">&nbsp;</div>-->
          </div>
        </div>
        <div class="nav-bar-bottom-item" appPermissionClaim [requiredPermission]="permissions.PROPERTY_LISTING" (click)="goToPropertySimple(); currentMobileTab='property'">
          <img *ngIf="currentMobileTab==='property'" class="icon-menu-svg" src="assets/icons/bottom-menu/property-active.png">
          <img *ngIf="currentMobileTab!=='property'" class="icon-menu-svg" src="assets/icons/bottom-menu/property.png">
          <div class="text-container">
            <p class="icon-descr-text" [ngClass]="{'icon-descr-text-bold': currentMobileTab=='property'}">{{'cc3-nav-bar.properties' | translate}}</p>
            <!--<div *ngIf="currentMobileTab == 'property'" class="nav-underline">&nbsp;</div>-->
          </div>
        </div>
        <div class="nav-bar-bottom-item" appPermissionClaim [requiredPermission]="permissions.BOOKING_CALENDARS" (click)="goToCalendarSimple(); currentMobileTab='calendar'">
          <img *ngIf="currentMobileTab==='calendar'" class="icon-menu-svg" src="assets/icons/bottom-menu/calendar-active..png">
          <img *ngIf="currentMobileTab!=='calendar'" class="icon-menu-svg" src="assets/icons/bottom-menu/calendar.png">
          <div class="text-container">
            <p class="icon-descr-text" [ngClass]="{'icon-descr-text-bold': currentMobileTab=='calendar'}">{{'cc3-nav-bar.calendars' | translate}}</p>
            <!--<div *ngIf="currentMobileTab == 'calendar'" class="nav-underline">&nbsp;</div>-->
          </div>
        </div>
        <div class="nav-bar-bottom-item" (click)="openMobilePlusMenu(); currentMobileTab='more'">
          <img class="icon-menu-svg" src="assets/icons/menu-dot.svg">
          <p class="icon-descr-text" [ngClass]="{'icon-descr-text-bold': currentMobileTab=='more'}">{{'cc3-nav-bar.more' | translate}}</p>
        </div>
      </div>

          <div *ngIf="bookingDotComChallenge.length>0" class="y3-flex-row y3-full-width">
            <div class="mobile-core1-booking-div" *ngFor="let bookingDotComAccount of bookingDotComChallenge">
              <div>{{'cc3-nav-bar.booking-dot-com-text' | translate}} : {{bookingDotComAccount.bookingDotComEmail}}</div>
              <div  class="y3-flex-column y3-full-width y3-margin-top-8">
                <div style="display: flex; flex-flow: column; align-items: center">
                  <app-button [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.mobile-booking-dot-com-ask-for-code' | translate" (debounceClick)="bookingRequestCode(bookingDotComAccount)"></app-button>
                </div>
                <div  *ngIf="demandalreadyasked" class="y3-flex-row  y3-flex-align-center y3-flex-justify-content-center y3-margin-top-8">
                  <input class="y3-input-text-small y3-margin-right-8" [(ngModel)]="challengeCode" >
                  <div style="display: flex; flex-flow: row; align-items: center">
                    <app-button [type]="'secondary-x-small-white'" [text]="'cc3-nav-bar.mobile-booking-dot-com-send-code' | translate" (debounceClick)="bookingSendCode(bookingDotComAccount, challengeCode)"></app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>

  </div>
</div>




